.jwplayer .jw-wrapper .jw-controls .jw-icon {
	z-index: 1 !important;
}

.video-cover {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	& .jwplayer.jw-flag-aspect-mode {
		height: 100% !important;
	}

	& .jw-aspect {
		height: 100% !important;
		padding-top: 0 !important;
	}

	& .jwplayer.jw-stretch-uniform .jw-media video {
		object-fit: cover;
		pointer-events: none;
	}

}

.jwplayer.jw-flag-floating.jw-floating-dismissible .jw-wrapper {
	bottom: calc(max(1rem, var(--footer-ad-height) + var(--spacing-xl)));
	top: auto;

	@media (--bp-medium) {
		bottom: calc(max(3rem, var(--footer-ad-height) + var(--spacing-xl)));
	}
}

.fade-in-top.visible:has(.jw-wrapper) {
	transform: none;
}
